// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointments-index-js": () => import("./../../../src/pages/appointments/index.js" /* webpackChunkName: "component---src-pages-appointments-index-js" */),
  "component---src-pages-appointments-virtual-tasting-js": () => import("./../../../src/pages/appointments/virtual-tasting.js" /* webpackChunkName: "component---src-pages-appointments-virtual-tasting-js" */),
  "component---src-pages-appointments-virtual-tour-js": () => import("./../../../src/pages/appointments/virtual-tour.js" /* webpackChunkName: "component---src-pages-appointments-virtual-tour-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-swag-js": () => import("./../../../src/templates/swag.js" /* webpackChunkName: "component---src-templates-swag-js" */),
  "component---src-templates-trade-js": () => import("./../../../src/templates/trade.js" /* webpackChunkName: "component---src-templates-trade-js" */),
  "component---src-templates-wine-js": () => import("./../../../src/templates/wine.js" /* webpackChunkName: "component---src-templates-wine-js" */)
}

